import React from "react";
import { Set } from "../../store/matches/models";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button,
  Link
} from "@material-ui/core";
import { SetValidation, validityStyle, validateSetsResult, sanitizeSetsResult } from "./utils";
import { update } from "ramda";
import SetResult from "./SetResult";
import ValidationSummary from "./ValidationSummary";
import ReactGA from 'react-ga';
import { PlayerDescriptor } from "../../store/leagues/models";

interface MatchResultDialogProps {
  open: boolean;
  onClosed: (success: boolean, sets: Set[]) => void;
  onHelpRequested: () => void;
  firstPlayer?: PlayerDescriptor;
  secondPlayer?: PlayerDescriptor;
}

const MatchResultDialog: React.FC<MatchResultDialogProps> = ({
  open,
  onClosed,
  onHelpRequested,
  firstPlayer,
  secondPlayer
}) => {
  const [validationState, setValidationState] = React.useState<SetValidation>();
  const [sets, setSets] = React.useState<Set[]>([
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 }
  ]);

  const handleClose = () => {
    onClosed(false, []);
  };

  React.useEffect(() => {

    if(open) {
      ReactGA.modalview('Match result dialog');
    }

  }, [open]);

  const handleSubmit = () => {
    const saneSets = sanitizeSetsResult(sets);
    const validationResult = validateSetsResult(saneSets);
    if (validationResult) {
      setValidationState(validationResult);
      return;
    } else {
      setValidationState(undefined);
    }

    onClosed(true, sets);
    setSets([
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 }
    ]);
  };

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Podaj wynik meczu</DialogTitle>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">
                {firstPlayer?.name} {firstPlayer?.surname} : {secondPlayer?.name} {secondPlayer?.surname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
                      {sets &&
                          sets.map((set: Set, i: number) => (
                              <Grid item key={i} sm={4} xs={12}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    {i + 1}. SET
                  </Typography>
                  <SetResult
                    style={{
                        width: '150px',
                      padding: "4px 0",
                      ...validityStyle(i + 1, validationState)
                    }}
                    set={set}
                    onSetChange={s => {
                      setSets([...update(i, {...s}, sets)]);
                    }}
                  ></SetResult>
                </Grid>
              ))}
          </Grid>
          <ValidationSummary value={validationState}></ValidationSummary>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Link href="javascript:void(0)" onClick={() => onHelpRequested()}>Wyślij wynik mailem</Link>
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default MatchResultDialog;
