import { ActionWithPayload, ActionWithError, ActionWithErrorCreator, ActionWithPayloadCreator, WithNotification, Severity } from "../../framework/action";
import { League } from "../leagues/models";
import { Match, MatchHeader, Set } from "./models";
import { SUBMIT_MATCH, SUBMIT_MATCH_FAILED, SUBMIT_MATCH_SUCCESS, GET_NEWEST_MATCHES, GET_NEWEST_MATCHES_FAILED, GET_NEWEST_MATCHES_SUCCESS, GET_PLAYERS_MATCHES, GET_PLAYERS_MATCHES_FAILED, GET_PLAYERS_MATCHES_SUCCESS, CONFIRM_MATCH, CONFIRM_MATCH_FAILED, CONFIRM_MATCH_SUCCESS, CANCEL_MATCH_SUCCESS, CANCEL_MATCH, CANCEL_MATCH_FAILED, SUBMIT_MATCH_RESULT, SUBMIT_MATCH_RESULT_SUCCESS, SUBMIT_MATCH_RESULT_FAILED, GET_LEAGUES_MATCHES, GET_LEAGUES_MATCHES_SUCCESS, GET_LEAGUES_MATCHES_FAILED, GET_PLAYERS_TOURNAMENT_MATCHES, GET_PLAYERS_TOURNAMENT_MATCHES_FAILED, GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS, CONFIRM_TOURNAMENT_MATCH, CONFIRM_TOURNAMENT_MATCH_FAILED, CONFIRM_TOURNAMENT_MATCH_SUCCESS, CANCEL_TOURNAMENT_MATCH, CANCEL_TOURNAMENT_MATCH_FAILED, CANCEL_TOURNAMENT_MATCH_SUCCESS, SUBMIT_TOURNAMENT_MATCH_RESULT, SUBMIT_TOURNAMENT_MATCH_RESULT_SUCCESS, SUBMIT_TOURNAMENT_MATCH_RESULT_FAILED, GET_TOURNAMENT_MATCHES, GET_TOURNAMENT_MATCHES_SUCCESS, GET_TOURNAMENT_MATCHES_FAILED } from "./actionTypes";
import { Action, ActionCreator } from "redux";
import { SUBMIT_TOURNAMENT_MATCH } from "../tournaments/actionTypes";

export interface SubmitMatch extends ActionWithPayload<{ league: League, match: Match, token: string }> { 
    type: typeof SUBMIT_MATCH;
}

export interface SubmitMatchFailed extends ActionWithError {
    type: typeof SUBMIT_MATCH_FAILED;
}

export interface SubmitMatchSuccess extends Action, WithNotification {
    type: typeof SUBMIT_MATCH_SUCCESS;
}

export interface GetNewestMatches extends ActionWithPayload<{ leagueId: string, year: string }> {
    type: typeof GET_NEWEST_MATCHES;
}

export interface GetNewestMatchesFailed extends ActionWithError {
    type: typeof GET_NEWEST_MATCHES_FAILED;
}

export interface GetNewestMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof GET_NEWEST_MATCHES_SUCCESS;
}

export interface ChangeMatchStatusPayload {
    year: string;
    leagueId: string;
    matchId: string;
    token: string;
    skip: number;
    take: number;
}

export interface ConfirmMatch extends ActionWithPayload<ChangeMatchStatusPayload> {
    type: typeof CONFIRM_MATCH;
}

export interface ConfirmMatchFailed extends ActionWithError {
    type: typeof CONFIRM_MATCH_FAILED;
}

export interface ConfirmMatchSuccess extends Action, WithNotification {
    type: typeof CONFIRM_MATCH_SUCCESS;
}

export interface CancelMatch extends ActionWithPayload<ChangeMatchStatusPayload> {
    type: typeof CANCEL_MATCH;
}

export interface CancelMatchFailed extends ActionWithError {
    type: typeof CANCEL_MATCH_FAILED;
}

export interface CancelMatchSuccess extends Action, WithNotification {
    type: typeof CANCEL_MATCH_SUCCESS;
}

export interface GetPlayersMatchesPayload {
    id: string,
    year: string,
    token: string,
    take: number,
    skip: number
}

export interface GetPlayersMatches extends ActionWithPayload<GetPlayersMatchesPayload> {
    type: typeof GET_PLAYERS_MATCHES
}

export interface GetPlayersMatchesFailed extends ActionWithError {
    type: typeof GET_PLAYERS_MATCHES_FAILED
}

export interface GetPlayersMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof GET_PLAYERS_MATCHES_SUCCESS
}

export interface MatchResultPayload {
    sets: Set[];
    token: string;
    matchId: string;
    leagueId: string;
    year: string;
    skip: number;
    take: number;
}

export interface MatchTournamentResultPayload {
    sets: Set[];
    token: string;
    matchId: string;
    tournamentId: string;
    year: string;
    skip: number;
    take: number;
}

export interface SubmitMatchResult extends ActionWithPayload<MatchResultPayload> {
    type: typeof SUBMIT_MATCH_RESULT;
}

export interface SubmitMatchResultSuccess extends Action, WithNotification {
    type: typeof SUBMIT_MATCH_RESULT_SUCCESS;
}

export interface SubmitMatchResultFailed extends ActionWithError {
    type: typeof SUBMIT_MATCH_RESULT_FAILED;
}

export interface GetLeagueMatchesPayload {
    year: string;
    id: string;
    skip: number;
    take: number;
}

export interface GetLeaguesMatches extends ActionWithPayload<GetLeagueMatchesPayload> {
    type: typeof GET_LEAGUES_MATCHES;
}

export interface GetLeaguesMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof GET_LEAGUES_MATCHES_SUCCESS;
}

export interface GetLeaguesMatchesFailed extends ActionWithError {
    type: typeof GET_LEAGUES_MATCHES_FAILED;
}

export interface GetTournamentMatches extends ActionWithPayload<GetLeagueMatchesPayload> {
    type: typeof GET_TOURNAMENT_MATCHES;
}

export interface GetTournamentMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof GET_TOURNAMENT_MATCHES_SUCCESS;
}

export interface getTournamentMatchesFailed extends ActionWithError {
    type: typeof GET_TOURNAMENT_MATCHES_FAILED;
}
 
//----------------------------

export interface ChangeTournamentMatchStatusPayload {
    year: string;
    tournamentId: string;
    matchId: string;
    token: string;
    skip: number;
    take: number;
}

export interface GetPlayersTournamentMatches extends ActionWithPayload<GetPlayersMatchesPayload> {
    type: typeof GET_PLAYERS_TOURNAMENT_MATCHES
}

export interface GetPlayersTournamentMatchesFailed extends ActionWithError {
    type: typeof GET_PLAYERS_TOURNAMENT_MATCHES_FAILED
}

export interface GetPlayersTournamentMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS
}

export interface ConfirmTournamentMatch extends ActionWithPayload<ChangeTournamentMatchStatusPayload> {
    type: typeof CONFIRM_TOURNAMENT_MATCH;
}

export interface ConfirmTournamentMatchFailed extends ActionWithError {
    type: typeof CONFIRM_TOURNAMENT_MATCH_FAILED;
}

export interface ConfirmTournamentMatchSuccess extends Action, WithNotification {
    type: typeof CONFIRM_TOURNAMENT_MATCH_SUCCESS;
}

export interface CancelTournamentMatch extends ActionWithPayload<ChangeTournamentMatchStatusPayload> {
    type: typeof CANCEL_TOURNAMENT_MATCH;
}

export interface CancelTournamentMatchFailed extends ActionWithError {
    type: typeof CANCEL_TOURNAMENT_MATCH_FAILED;
}

export interface CancelTournamentMatchSuccess extends Action, WithNotification {
    type: typeof CANCEL_TOURNAMENT_MATCH_SUCCESS;
}

export interface TournamentMatchResultPayload {
    sets: Set[];
    token: string;
    matchId: string;
    tournamentId: string;
    year: string;
    skip: number;
    take: number;
}

export interface SubmitTournamentMatchResult extends ActionWithPayload<MatchTournamentResultPayload> {
    type: typeof SUBMIT_TOURNAMENT_MATCH_RESULT;
}

export interface SubmitTournamentMatchResultSuccess extends Action, WithNotification {
    type: typeof SUBMIT_TOURNAMENT_MATCH_RESULT_SUCCESS;
}

export interface SubmitTournamentMatchResultFailed extends ActionWithError {
    type: typeof SUBMIT_TOURNAMENT_MATCH_RESULT_FAILED;
}


export const submitMatch : ActionWithPayloadCreator<{ league: League, match: Match, token: string }> = ({ league, match, token}) => ({
    payload: {
        league, match, token
    },
    type: SUBMIT_MATCH
});

const handleSubmitMatchFailedDescription = (error: any) => {
    if(!error || !error.id) {
        return "Nie udało nam się zapisać Twojego meczu, spróbuj ponownie!";
    }
    switch(error.id) {
        case 1: return "Liga nie pozwala jeszcze na umawianie nowych meczów";
        case 2: return "Zawodnik nie jest zapisany do ligii";
        case 3: return "Przeciwnik nie jest zapisany do ligii";
        case 4: return "Klub nie wspiera tej ligii";
        case 5: return "Zawodnicy rozegrali już mecz między sobą, lub mają taki mecz umówiony";
        case 16: return "Jeden z zawodników jest ma konto próbne i przekroczył już maksymalną liczbę meczów (3)";
        case 17: return "Zawodnik mający konto próbne nie może rozgrywać meczy w ostatnim tygodniu";
        case 18: return "Mecz zarejestrowany w ostatnim dniu ligii";
        case 19: return "Mecz zaplanowany na datę po zakończeniu ligii";
        default: return "Nie udało nam się zapisać Twojego meczu, spróbuj ponownie!";
    }
}

const handleSubmitMatchFailedContext = (error: any) => {
    if(error && error.id) {
        return {
            warning: true
        };
    } 

    return undefined;
}

export const submitMatchFailed : ActionWithErrorCreator<any> = (error) => ({ type: SUBMIT_MATCH_FAILED, error, description: handleSubmitMatchFailedDescription(error), context: handleSubmitMatchFailedContext(error) });

export const submitMatchSuccess : ActionCreator<SubmitMatchSuccess> = () => ({ type: SUBMIT_MATCH_SUCCESS, notification: { message: "Świetnie, umówiłeś swój mecz. Czekaj na potwierdzenie ze strony rywala.", severity: Severity.Success } });

export const getNewestMatches: ActionWithPayloadCreator<{ leagueId: string, year: string }> = ({ leagueId, year }) => ({
    type: GET_NEWEST_MATCHES,
    payload: {
        leagueId,
        year
    }
});

export const getNewestMatchesFailed: ActionWithErrorCreator = (error) => ({
    type: GET_NEWEST_MATCHES_FAILED,
    error,
    description: "Błąd ładowania ostatnich meczów"
});

export const getNewestMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (matches) => ({
    type: GET_NEWEST_MATCHES_SUCCESS,
    payload: matches
});

export const getPlayersMatches: ActionWithPayloadCreator<GetPlayersMatchesPayload> = (payload) => ({
    type: GET_PLAYERS_MATCHES,
    payload
});

export const getPlayersMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (matches) => ({
    type: GET_PLAYERS_MATCHES_SUCCESS,
    payload: matches
});

export const getPlayersMatchesFailed: ActionWithErrorCreator = (error) => ({
    type: GET_PLAYERS_MATCHES_FAILED,
    error,
    description: 'Nie udało nam się załadować Twoich meczów. Spróbuj ponownie'
});

export const confirmMatch: ActionWithPayloadCreator<ChangeMatchStatusPayload> = (payload) => ({
    type: CONFIRM_MATCH,
    payload
});

export const confirmMatchSuccess: ActionCreator<ConfirmMatchSuccess> = () => ({
    type: CONFIRM_MATCH_SUCCESS,
    notification: {
        message: 'Dzięki za potwierdzenie meczu! Powodzenia!',
        severity: Severity.Success
    }
});

export const confirmMatchFailed: ActionWithErrorCreator = (error) => ({
    type: CONFIRM_MATCH_FAILED,
    error,
    description: handleSubmitMatchFailedDescription(error),
    context: handleSubmitMatchFailedContext(error)
});

export const cancelMatch: ActionWithPayloadCreator<ChangeMatchStatusPayload> = (payload) => ({
    type: CANCEL_MATCH,
    payload
});

export const cancelMatchSuccess: ActionCreator<CancelMatchSuccess> = () => ({
    type: CANCEL_MATCH_SUCCESS,
    notification: {
        message: 'Zrozumiałem. Szkoda, że odwołałeś mecz!',
        severity: Severity.Success
    }
});

export const cancelMatchFailed: ActionWithErrorCreator = (error) => ({
    type: CANCEL_MATCH_FAILED,
    error,
    description: 'Nie udało nam się anulować Twojego meczu. Spróbuj ponownie później'
});

export const submitMatchResult: ActionWithPayloadCreator<MatchResultPayload> = (payload) => ({
    type: SUBMIT_MATCH_RESULT,
    payload
});

export const submitMatchResultFailed: ActionWithErrorCreator = (error) => ({
    type: SUBMIT_MATCH_RESULT_FAILED,
    error,
    description: handleSubmitMatchFailedDescription(error),
    context: handleSubmitMatchFailedContext(error)
});

export const submitMatchResultSuccess: ActionCreator<SubmitMatchResultSuccess> = () => ({
    type: SUBMIT_MATCH_RESULT_SUCCESS,
    notification: {
        message: 'Świetnie! Wynik meczu został zarejestrowany',
        severity: Severity.Success
    }
});

export const getLeaguesMatches: ActionWithPayloadCreator<GetLeagueMatchesPayload> = (payload) => ({
    type: GET_LEAGUES_MATCHES,
    payload
});

export const getLeaguesMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (payload) => ({
    type: GET_LEAGUES_MATCHES_SUCCESS,
    payload
});

export const getLeaguestMatchesFailed: ActionWithErrorCreator = (error) => ({
    type: GET_LEAGUES_MATCHES_FAILED,
    error,
    description: 'Nie udało się pobrać meczy w lidzie. Spróbuj ponownie.'
});

// ------------

export const getTournamentMatches: ActionWithPayloadCreator<GetLeagueMatchesPayload> = (payload) => ({
    type: GET_TOURNAMENT_MATCHES,
    payload
});

export const getTournamentMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (payload) => ({
    type: GET_TOURNAMENT_MATCHES_SUCCESS,
    payload
});

export const getTournamentMatchesFailed: ActionWithErrorCreator = (error) => ({
    type: GET_TOURNAMENT_MATCHES_FAILED,
    error,
    description: 'Nie udało się pobrać meczy w turnieju. Spróbuj ponownie.'
})

export const getPlayersTournamentMatches: ActionWithPayloadCreator<GetPlayersMatchesPayload> = (payload) => ({
    type: GET_PLAYERS_TOURNAMENT_MATCHES,
    payload
});

export const getPlayersTournamentMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (matches) => ({
    type: GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS,
    payload: matches
});

export const getPlayersTournamentMatchesFailed: ActionWithErrorCreator = (error) => ({
    type: GET_PLAYERS_TOURNAMENT_MATCHES_FAILED,
    error,
    description: 'Nie udało nam się załadować Twoich meczów. Spróbuj ponownie'
});

export const confirmTournamentMatch: ActionWithPayloadCreator<ChangeTournamentMatchStatusPayload> = (payload) => ({
    type: CONFIRM_TOURNAMENT_MATCH,
    payload
});

export const confirmTournamentMatchSuccess: ActionCreator<ConfirmTournamentMatchSuccess> = () => ({
    type: CONFIRM_TOURNAMENT_MATCH_SUCCESS,
    notification: {
        message: 'Dzięki za potwierdzenie meczu! Powodzenia!',
        severity: Severity.Success
    }
});

export const confirmTournamentMatchFailed: ActionWithErrorCreator = (error) => ({
    type: CONFIRM_TOURNAMENT_MATCH_FAILED,
    error,
    description: handleSubmitMatchFailedDescription(error),
    context: handleSubmitMatchFailedContext(error)
});

export const cancelTournamentMatch: ActionWithPayloadCreator<ChangeTournamentMatchStatusPayload> = (payload) => ({
    type: CANCEL_TOURNAMENT_MATCH,
    payload
});

export const cancelTournamentMatchSuccess: ActionCreator<CancelTournamentMatchSuccess> = () => ({
    type: CANCEL_TOURNAMENT_MATCH_SUCCESS,
    notification: {
        message: 'Zrozumiałem. Szkoda, że odwołałeś mecz!',
        severity: Severity.Success
    }
});

export const cancelTournamentMatchFailed: ActionWithErrorCreator = (error) => ({
    type: CANCEL_TOURNAMENT_MATCH_FAILED,
    error,
    description: 'Nie udało nam się anulować Twojego meczu. Spróbuj ponownie później'
});

export const submitTournamentMatchResult: ActionWithPayloadCreator<TournamentMatchResultPayload> = (payload) => ({
    type: SUBMIT_TOURNAMENT_MATCH_RESULT,
    payload
});

export const submitTournamentMatchResultFailed: ActionWithErrorCreator = (error) => ({
    type: SUBMIT_TOURNAMENT_MATCH_RESULT_FAILED,
    error,
    description: handleSubmitMatchFailedDescription(error),
    context: handleSubmitMatchFailedContext(error)
});

export const submitTournamentMatchResultSuccess: ActionCreator<SubmitTournamentMatchResultSuccess> = () => ({
    type: SUBMIT_TOURNAMENT_MATCH_RESULT_SUCCESS,
    notification: {
        message: 'Świetnie! Wynik meczu został zarejestrowany',
        severity: Severity.Success
    }
});

// -----------------------------------



export type Actions = 
    | SubmitMatch
    | SubmitMatchFailed
    | SubmitMatchSuccess
    | GetNewestMatches
    | GetNewestMatchesFailed
    | GetNewestMatchesSuccess
    | GetPlayersMatches
    | GetPlayersMatchesSuccess
    | GetPlayersMatchesFailed
    | GetLeaguesMatches
    | GetLeaguesMatchesFailed
    | GetLeaguesMatchesSuccess
    | SubmitMatchResult
    | ConfirmMatch
    | CancelMatch
    | GetPlayersTournamentMatches
    | GetPlayersTournamentMatchesFailed
    | GetPlayersTournamentMatchesSuccess
    | SubmitTournamentMatchResult
    | ConfirmTournamentMatch
    | CancelTournamentMatch
    | GetTournamentMatches
    | GetTournamentMatchesSuccess
    | getTournamentMatchesFailed