import React from "react";
import { Stepper, Step, StepButton } from "@material-ui/core";
import LeagueSelector from "./LeagueSelector";
import PlayerType from "./PlayerType";
import PlayerStep from "./PlayerStep";
import ConfirmationsStep from "./ConfirmationsStep";
import RegistrationProgress from "./RegistrationProgress";

interface RegistrationWizardProps {
    startingStep: number;
}

const getSteps = () => {
    return [
        "Wybór ligi",
        "Typ profilu",
        "Profil zawodnika",
        "Zapis do ligi",
        "Zakończenie"
    ]
}


const RegistrationWizard: React.FC<RegistrationWizardProps> = ({ startingStep }) => {

    const [activeStep, setActiveStep] = React.useState(startingStep);
    const steps = getSteps();

    React.useEffect(() => setActiveStep(startingStep), [startingStep]);

    const getStepContent = (idx: number) => {
        switch(idx) {
            case 0: {
                return <LeagueSelector onProceed={() => setActiveStep(activeStep+1)} />
            }
            case 1: {
                return <PlayerType onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)} />
            }
            case 2: {
                return <PlayerStep onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)} />
            }
            case 3: {
                return <ConfirmationsStep onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)}></ConfirmationsStep>
            }
            case 4: {
                return <RegistrationProgress></RegistrationProgress>
            }
        }
    }

    return <>
        <Stepper nonLinear activeStep={activeStep}>
            {
                steps.map((step) => (
                    <Step key={step}>
                        <StepButton>
                            {step}
                        </StepButton>
                    </Step>
                ))
            }
        </Stepper>
        {
            getStepContent(activeStep)
        }
    </>
};

export default RegistrationWizard;