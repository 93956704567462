import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button} from "@material-ui/core";
import { update } from "ramda";
import ReactGA from 'react-ga';
import { PlayerDescriptor } from "../../../store/leagues/models";
import { Set } from "../../../store/matches/models";
import SetResult from "../../matches/SetResult";

interface TournamentMatchResultDialogProps {
  open: boolean;
  onClosed: (success: boolean, sets: Set[]) => void;
  firstPlayer?: PlayerDescriptor;
  secondPlayer?: PlayerDescriptor;
}

const TournamentMatchResultDialog: React.FC<TournamentMatchResultDialogProps> = ({
  open,
  onClosed,
  firstPlayer,
  secondPlayer
}) => {

  const [sets, setSets] = React.useState<Set[]>([
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 }
  ]);

  const handleClose = () => {
    onClosed(false, []);
  };

  React.useEffect(() => {

    if(open) {
      ReactGA.modalview('Match result dialog');
    }

  }, [open]);

  const handleSubmit = () => {
    onClosed(true, sets);
    setSets([
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 }
    ]);
  };

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Podaj wynik meczu</DialogTitle>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">
                {firstPlayer?.name} {firstPlayer?.surname} : {secondPlayer?.name} {secondPlayer?.surname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
                      {sets &&
                          sets.map((set: Set, i: number) => (
                              <Grid item key={i} sm={4} xs={12}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    {i + 1}. SET
                  </Typography>
                  <SetResult
                    style={{
                        width: '150px',
                      padding: "4px 0"
                    }}
                    set={set}
                    onSetChange={s => {
                      setSets([...update(i, {...s}, sets)]);
                    }}
                  ></SetResult>
                </Grid>
              ))}
          </Grid>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default TournamentMatchResultDialog;
