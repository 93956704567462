import { Player } from "../players/models";

export interface RegisterToLeague {
    league: { id: string, season: string, secondPlayerSurname: string, secondPlayerName: string };
    city: string;
}

export enum PlayerType {
    REGULAR,
    TRIAL
}

export interface PriceInfo {

    basePrice: number;
    finalPrice: number;

    promoCodeDescription: string;
    promoCode: string;
    promoCodeOk: boolean;

    referralDescription: string;
    referral: string;
    referralOk: boolean;
}

export interface RegistrationData {
    target: RegisterToLeague;
    playerType: PlayerType;
    playerData: Player;
    promoCode: string;
    referral: string;
    token: string;
    isUpgrading: boolean;
}

export interface RegistrationStatus {

    isSuccess: boolean;
    paymentLink: string;
    errorMessage: string;
}