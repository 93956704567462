import { CircularProgress, Grid, IconButton, Tooltip, Typography, List, ListItem, ListItemIcon, ListItemText, Button, ListItemSecondaryAction, Hidden, useTheme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Data } from "../../framework/data";
import { MatchHeader, MatchStatus, Set } from "../../store/matches/models";
import { Player } from "../../store/players/models";
import PlayersMatches from "./PlayersMatches";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { AppState } from "../../store/reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { loadMyConfirmedMatches, loadMyUnconfirmedMatches } from "../../store/desktop/actions";
import logger from "../../framework/logger";
import { cancelMatch, confirmMatch, submitMatchResult } from "../../store/matches/actions";
import { Alert } from "@material-ui/lab";
import MatchResultDialog from "../matches/MatchResultDialog";
import { LeagueWithPaymentStatus } from "../../store/leagues/models";
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import StatsContainer from "../stats/StatsContainer";
import { useAuth0 } from "@auth0/auth0-react";
import EventNoteIcon from '@material-ui/icons/EventNote';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Link } from "react-router-dom";
import { PlayerType } from "../../store/registration/model";
import { Club } from "../../store/clubs/models";

interface DesktopPageProps {
    unconfirmedMatches: Data<MatchHeader[]>;
    confirmedMatches: Data<MatchHeader[]>;
    player: Data<Player>;
    leagues: Data<LeagueWithPaymentStatus[]>;
    clubs: Data<Club[]>;

    loadUnconfirmedMatches: (token: string) => void;
    loadConfirmedMatches: (token: string) => void;

    cancelMatch: (year: string, leagueId: string, matchId: string, token: string) => void;
    confirmMatch: (year: string, leagueId: string, matchId: string, token: string) => void;
    submitMatchResult: (sets: Set[], year: string, leagueId: string, matchId: string, token: string) => void;
}

const DesktopPage: React.FC<DesktopPageProps> = ({ unconfirmedMatches, confirmedMatches, player, leagues, loadConfirmedMatches, loadUnconfirmedMatches, cancelMatch, confirmMatch, submitMatchResult, clubs }) => {

    const { getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {

        if (player.type === "LOADED") {
            getAccessTokenSilently().then((token: string) => {
                loadUnconfirmedMatches(token);
                loadConfirmedMatches(token);
            });
        }

    }, [player]);

    const theme = useTheme();
    const isSmallOrUpper = useMediaQuery(theme.breakpoints.up('sm'));

    const [matchResultDialogOpen, setMatchResultDialogOpen] = React.useState<boolean>(false);
    const [currentMatch, setCurrentMatch] = React.useState<MatchHeader>();

    const handleMatchResult = async (success: boolean, sets: Set[]) => {
        if (success && currentMatch) {
            const token = await getAccessTokenSilently();
            logger.logEvent(`Submitting match result for ${currentMatch.id}`, { match: JSON.stringify(currentMatch), sets: JSON.stringify(sets) });
            submitMatchResult(sets, leagues.type === "LOADED" ? leagues.value.find(l => l.id === currentMatch.league)?.year || "" : "", currentMatch.league, currentMatch.id, token);
        }
        setMatchResultDialogOpen(false);
    };

    const handleHelpRequested = () => {
        window.location.assign(`mailto:support@smarttennis.pl?subject=Wynik%20meczu%20${currentMatch?.id}&body=Proszę%20podać%20wynik%20meczu`);
    }

    return (<>
        <MatchResultDialog
            open={matchResultDialogOpen}
            firstPlayer={currentMatch?.firstPlayer}
            secondPlayer={currentMatch?.secondPlayer}
            onClosed={(success, sets) => handleMatchResult(success, sets)}
            onHelpRequested={() => handleHelpRequested()}
        ></MatchResultDialog>
        {

            player.type === "LOADED" && player.value && leagues.type === "LOADED" && leagues.value && clubs.type === "LOADED" && clubs.value
                ? (

                    (unconfirmedMatches.type === "LOADED" && confirmedMatches.type === "LOADED") ? (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="h6" color="primary">Niepotwierdzone</Typography>


                                    <PlayersMatches
                                        matches={unconfirmedMatches.value}
                                        currentPlayer={player.value}
                                        leagues={leagues.value}
                                        clubs={clubs.value}
                                        actions={(m) => {
                                            const firstAction = m.status === MatchStatus.New &&
                                                m.createdBy &&
                                                m.createdBy.id !== player.value.id &&
                                                (m.firstPlayer.id === player.value.id ||
                                                    m.secondPlayer.id === player.value.id) ? (
                                                    <Tooltip
                                                        title="Potwierdź, że jesteś umówiony na ten mecz. Zablokowane, jeśli próbujesz zatwierdzić mecz po dacie jest odbycia."
                                                        key="1"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            disabled={m.date < new Date()}
                                                            onClick={async () => {
                                                                const token = await getAccessTokenSilently();
                                                                logger.logEvent(`Confirming match ${m.id}`, { match: m });
                                                                confirmMatch(leagues.value.find(l => l.id === m.league)?.year || "", m.league, m.id, token);
                                                            }}
                                                        >
                                                            <CheckIcon></CheckIcon>
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                );

                                            const secondAction =
                                                (m.status === MatchStatus.New || m.status === MatchStatus.Confirmed) &&
                                                    (m.firstPlayer.id === player.value.id ||
                                                        m.secondPlayer.id === player.value.id) ? (
                                                        <Tooltip title="Anuluj mecz" key="2">
                                                            <IconButton
                                                                size="small"
                                                                onClick={async () => {
                                                                    const token = await getAccessTokenSilently();
                                                                    logger.logEvent(`Cancelling match ${m.id}`, { match: m });
                                                                    cancelMatch(leagues.value.find(l => l.id === m.league)?.year || "", m.league, m.id, token);
                                                                }}
                                                            >
                                                                <ClearIcon></ClearIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : <></>;

                                            return (<>{firstAction} {secondAction}</>);


                                        }}
                                    >

                                    </PlayersMatches>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="h6" color="primary">Potwierdzone mecze</Typography>

                                    <PlayersMatches
                                        matches={confirmedMatches.value}
                                        currentPlayer={player.value}
                                        leagues={leagues.value}
                                        clubs={clubs.value}
                                        actions={(m) => {
                                            const firstAction =
                                                m.status === MatchStatus.Confirmed && m.date <= new Date() &&
                                                    (m.firstPlayer.id === player.value.id ||
                                                        m.secondPlayer.id === player.value.id) ? (
                                                        <Tooltip title="Podaj wynik meczu" key="3">
                                                            <IconButton
                                                            size="small"
                                                                onClick={() => {
                                                                    setCurrentMatch(m);
                                                                    setMatchResultDialogOpen(true);
                                                                }}
                                                            >
                                                                <PostAddIcon></PostAddIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : <></>;

                                            const secondAction =
                                                (m.status === MatchStatus.New || m.status === MatchStatus.Confirmed) &&
                                                    (m.firstPlayer.id === player.value.id ||
                                                        m.secondPlayer.id === player.value.id) ? (
                                                        <Tooltip title="Anuluj mecz" key="2">
                                                            <IconButton
                                                            size="small"
                                                                onClick={async () => {
                                                                    const token = await getAccessTokenSilently();
                                                                    logger.logEvent(`Cancelling match ${m.id}`, { match: m });
                                                                    cancelMatch(leagues.value.find(l => l.id === m.league)?.year || "", m.league, m.id, token);
                                                                }}
                                                            >
                                                                <ClearIcon></ClearIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : <></>;

                                            return (<>{firstAction} {secondAction}</>);


                                        }}
                                    >
                                    </PlayersMatches>
                                </Grid>

                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" color="primary">Moje ligi</Typography>

                                    {
                                        (leagues.type === "LOADED") ? (
                                            <List>
                                                {
                                                    leagues.value.sort((a, b) => b.year.localeCompare(a.year)).map(i => (
                                                        <ListItem key={i.id} button style={{ border: '1px solid #e0e0e0', paddingRight: (isSmallOrUpper ? '156px' : '64px') }}>
                                                            <Hidden xsDown>
                                                                <ListItemIcon>
                                                                    <SportsTennisIcon />
                                                                </ListItemIcon>
                                                            </Hidden>
                                                            <ListItemText>
                                                                {i.name}
                                                            </ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <Button component={Link} to={`/match-offers/${i.year}/${i.id}`}>
                                                                    <EventNoteIcon />
                                                                    <Hidden xsDown>
                                                                        Giełda meczów
                                                                    </Hidden>
                                                                </Button>
                                                                <Button component={Link} to={`/leagues/${i.year}/${i.id}`}>
                                                                    <TableChartIcon />
                                                                    <Hidden xsDown>
                                                                        Tabela
                                                                    </Hidden>
                                                                </Button>
                                                                {
                                                                    i.playerType === PlayerType.TRIAL ? (
                                                                        <Button component={Link} to={`/signup?leagueId=${i.id}&leagueYear=${i.year}`}>
                                                                    <AttachMoneyIcon />
                                                                    <Hidden xsDown>
                                                                        Konto SMART
                                                                    </Hidden>
                                                                </Button>
                                                                    ) : (<></>)
                                                                }
                                                                
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ))
                                                }

                                            </List>
                                        ) : (
                                                <div style={{
                                                    width: "64px",
                                                    height: "64px",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}>
                                                    <CircularProgress />
                                                </div>
                                            )
                                    }
                                </Grid>
                            </Grid>

                            <StatsContainer playerId={btoa(player.value.id!)}></StatsContainer>
                        </>
                    ) : (
                            <div style={{
                                width: "64px",
                                height: "64px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}>
                                <CircularProgress />
                            </div>
                        )
                ) : (
                    <Alert severity="warning">
                        Musisz utworzyć profil zawodnika i zapisać się do wybranej przez siebie ligi aby móc oglądać swoje mecze
                    </Alert>
                )
        }

    </>);
}

const mapStateToProps = (state: AppState) => ({
    unconfirmedMatches: state.desktop.myUnconfirmedMatches,
    confirmedMatches: state.desktop.myConfirmedMatches,
    player: state.player.me,
    leagues: state.leagues.leaguesWithPayments,
    clubs: state.clubs.clubs
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadUnconfirmedMatches: (token: string) => dispatch(loadMyUnconfirmedMatches(token)),
    loadConfirmedMatches: (token: string) => dispatch(loadMyConfirmedMatches(token)),

    cancelMatch: (year: string, leagueId: string, matchId: string, token: string) => dispatch(cancelMatch({ leagueId, matchId, skip: 0, take: 25, year, token })),
    confirmMatch: (year: string, leagueId: string, matchId: string, token: string) => dispatch(confirmMatch({ leagueId, matchId, skip: 0, take: 25, year, token })),
    submitMatchResult: (sets: Set[], year: string, leagueId: string, matchId: string, token: string) => dispatch(submitMatchResult({ leagueId, matchId, skip: 0, take: 25, year, token, sets }))
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopPage);