import * as React from "react";
import { Grid } from "@material-ui/core";
import Sponsor from "./Sponsor";

const SponsorsPage: React.FC = () => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="La-Le" 
                    logoUrl="/logo/lale.png" 
                    url="https://la-le.pl/"
                    description="Producent naturalnych kosmetyków do pielęgnacji ciała i twarzy wytwarzanych z ekologicznych produktów. Kosmetyki La-Le są wykonywane w krakowskiej manufakturze w sposób ręczny na bazie roślinnych składników i tradycyjnych receptur."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="DUOLIFE" 
                    logoUrl="/logo/duolife.png" 
                    url="https://myduolife.com/"
                    description="DuoLife S.A. jest jedną z największych Polskich marek oferujących naturalne suplementy diety. Firma od 2012 roku stara się sprostać oczekiwaniom klientów nie tylko na rodzimym rynku, ale i za granicą. Produkty DuoLife dostępne są aż w 30 krajach świata."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Pro-Fiz" 
                    logoUrl="/logo/profiz.png" 
                    url="https://www.facebook.com/profizcentrumfizjoterapii/"
                    description="Centrum fizjoterapii w Krakowie - zabiegi rehabilitacyjne, doradztwo, treningi, szybki powrót po kontuzji."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="MM SPORT" 
                    logoUrl="/logo/mm_sport.png" 
                    url="https://mmsport.pl/"
                    description="Sklep z odzieżą sportową oferujące tysiące oryginalnych produktów w atrakcyjnych cenach dla mężczyzn, kobiet i dzieci."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="ChilliFlex" 
                    logoUrl="/logo/chilli.png" 
                    url="https://chilliflex.co/"
                    description="Oferuje biura oraz stylową przestrzeń dla biznesu w wielu miastach w Polsce, w tym w Krakowie."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Tenisista.pl" 
                    logoUrl="/logo/tenisista.png" 
                    url="https://www.tenisista.pl/"
                    description="Sklep tenisowy oferujący wysokiej jakości sprzęt, odzież sportową i akcesoria."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Head" 
                    logoUrl="/logo/head.png" 
                    url="https://www.head.com/pl_PL/tennis.html"
                    description="Producent sprzętu tenisowego."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Sema-print" 
                    logoUrl="/logo/sema_print.png" 
                    url="https://semaprint.com.pl/"
                    description="Firma z siedzibą w Krakowie zajmująca się importem oraz produkcją odzieży promocyjnej i artykułów reklamowych."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Błonia Sport" 
                    logoUrl="/logo/blonia_sport.png" 
                    url="https://bloniasport.pl/"
                    description="Partner SmartTennis, który udostępnia korty tenisowe na rozgrywanie turniejów oraz finałów."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Bon Voyage" 
                    logoUrl="/logo/bon_voyage.jfif" 
                    url="https://bonvoyage.pl/rb/"
                    description="Biuro podróży oferujące egzotyczne i luksusowe wakacje."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="IOLA" 
                    logoUrl="/logo/iola.png" 
                    url="https://iola.pl/"
                    description="Platforma do tworzenia, zarządzania i automatyzacji marketingu internetowego oferująca wsparcie wielu systemach reklamowych."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Nova Klubova" 
                    logoUrl="/logo/nowa_klubowa.png" 
                    url="https://novaklubova.pl/"
                    description="Nova Klubova to połączenie klubu, coctail baru i restauracji w centrum krakowskiego kazimierza. Odbywają się tam imprezy na zakończenie sezonu ligowego."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="PAR HOUSE AGENCY" 
                    logoUrl="/logo/par_house.jfif" 
                    url="https://parhouse.agency/"
                    description="Agencja specjalizująca się w dziedzinie projektowania interfejsów użytkownika (UX/UI), grafiki komputerowej oraz stron internetowych."/>
            </Grid>
            
        </Grid>
    )

};

export default SponsorsPage;

