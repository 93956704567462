import React from "react";
import { LeagueWithCurrentUserAssignment } from "../../store/leagues/models";
import { Data, isLoaded } from "../../framework/data";
import MatchesTable from "./MatchesTable";
import { MatchHeader } from "../../store/matches/models";
import { withRouter, match } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { usersAssignedLeague } from "../../store/leagues/selectors";
import { Dispatch } from "redux";
import { loadLeagueDetails } from "../../store/leagues/actions";
import { getLeaguesMatches } from "../../store/matches/actions";
import { Button } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

interface AllMatchesPageProps {
  league: Data<LeagueWithCurrentUserAssignment>;
  matches: Data<MatchHeader[]>;
  match: match<{ year: string; id: string }>;
  loadLeagueDetails: (year: string, id: string) => void;
  loadLeagueMatches: (
    year: string,
    id: string,
    skip: number,
    take: number
  ) => void;
}

const AllMatchesPage: React.FC<AllMatchesPageProps> = ({
  league,
  loadLeagueDetails,
  loadLeagueMatches,
  matches,
  match
}) => {
  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  React.useEffect(() => {
    var id = match.params.id;
    var year = match.params.year;

    if (!isLoaded(league)) {
      loadLeagueDetails(year, id);
    }

    if (league.type === "LOADED") {
        loadLeagueMatches(league.value.year, league.value.id, 0, 25);
      };

  }, [
    match.params.id,
    match.params.year,
    loadLeagueDetails,
    league,
    loadLeagueMatches
  ]);

  return (
    <>
      {league.type === "LOADED" && matches.type === "LOADED" ? (
        <MatchesTable
          matches={matches.value}
          onLoadData={async (page, pageSize) => {
            const skip = page * pageSize;
            const take = pageSize;
            loadLeagueMatches(
              league.value.year,
              league.value.id,
              skip,
              take
            );
            setPage(page);
            setPageSize(pageSize);
          }}
          page={page || 0}
          pageSize={pageSize || 25}
        ></MatchesTable>
      ) : (
        <></>
      )}
            <Button startIcon={<ChevronLeftIcon/>} onClick={() => window.history.back()}>Powrót</Button>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
    return {
      league: usersAssignedLeague(state),
      matches: state.matches.currentLeagueAllMatchesPage
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      loadLeagueDetails: (year: string, id: string) => dispatch(loadLeagueDetails({ year, id })),
      loadLeagueMatches: (
        year: string,
        id: string,
        skip: number,
        take: number,
      ) => dispatch(getLeaguesMatches({ year, id, skip, take })),
      
    };
  };

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AllMatchesPage)
  );