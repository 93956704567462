import React from "react";
import { connect } from "react-redux";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { PlayerType, RegisterToLeague, PriceInfo } from "../../store/registration/model";
import PhoneConfirmation from "./PhoneConfirmation";
import { useAuth0 } from "@auth0/auth0-react";
import { Dispatch } from "redux";
import { sendSmsVerificationCode, verifySmsCode, getPriceInfoForLeague } from "../../store/registration/actions";
import { Divider, Button, Checkbox, Link } from "@material-ui/core";
import PriceConfirmation from "./PriceConfirmation";
import { Data } from "../../framework/data";
import { LeagueHeader } from "../../store/leagues/models";
import { currentPlayer } from "../../store/players/selectors";

interface ConfirmationStepProps {

    playerType: PlayerType;
    playerInfo?: Player;

    phoneVerified: boolean | undefined;
    phoneAuthMessage: Data<string>;
    isVerifying: boolean;

    leagueSelection: RegisterToLeague;
    leagues: Data<LeagueHeader[]>;
    currentPlayer: Data<Player>;
    priceInfo: Data<PriceInfo>;

    onProceed: () => void;
    onMoveBack: () => void;

    sendSmsWithVericationCode: (token: string, phoneNumber: string) => void;
    verifySmsCode: (token: string, code: string) => void;
    getPriceInfoForLeague: (id: string, season: string, promoCode: string, referral: string, playerId?: string) => void;
}

const ConfirmationStep: React.FC<ConfirmationStepProps> = ({
    playerType, playerInfo, phoneVerified, isVerifying, leagueSelection, leagues, currentPlayer, priceInfo, phoneAuthMessage,
    sendSmsWithVericationCode, verifySmsCode, onProceed, onMoveBack, getPriceInfoForLeague }) => {

    const { getAccessTokenSilently } = useAuth0();

    const [tocApproved, setTocApproved] = React.useState<boolean>(false);
    const [isPhoneVerificationForTrialNeeded, setPhoneVerificationForTrialNeeded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (currentPlayer.type === "LOADED" && playerInfo?.isVerified && playerInfo.phoneNumber === currentPlayer.value.phoneNumber) {
            setPhoneVerificationForTrialNeeded(false);
        } else {
            setPhoneVerificationForTrialNeeded(true);
        }
    }, []);


    let x = (<></>);

    switch (playerType) {
        case PlayerType.TRIAL: {
            if (isPhoneVerificationForTrialNeeded) {
                x = (<PhoneConfirmation phoneAuthMessage={phoneAuthMessage.type === "LOADED" ? phoneAuthMessage.value : ""} phoneVerified={phoneVerified} isVerifying={isVerifying} phone={playerInfo?.phoneNumber || ""} onSendSmsMessage={async () => {
                    if (playerInfo) {
                        const token = await getAccessTokenSilently();
                        sendSmsWithVericationCode(token, playerInfo.phoneNumber!);
                    }

                }} onPhoneChallenged={async (code) => {
                    const token = await getAccessTokenSilently();
                    verifySmsCode(token, code);
                }}></PhoneConfirmation>);
            } 
            break;
        }
        case PlayerType.REGULAR: {
            x = leagues.type === "LOADED" && currentPlayer.type === "LOADED" ? (<PriceConfirmation
                isVerifying={isVerifying}
                leagueId={leagueSelection.league!.id}
                leagues={leagues.value}
                priceInfo={priceInfo.type === "LOADED" ? priceInfo.value : undefined}
                getPriceInfoForLeague={(id, year, promoCode, referral) => getPriceInfoForLeague(id, year, promoCode, referral, currentPlayer.value?.id)}
            ></PriceConfirmation>) : (<></>);
            break;
        }
    }

    return (<>
        {x}
        <Checkbox
            value={tocApproved}
            onChange={(e) => setTocApproved(e.target.checked)}
        ></Checkbox>
        Zgadzam się z{" "}
        <Link
            href="Smart_Tennis_regulamin.pdf"
            target="_blank"
            color="primary"
        >
            regulaminem ligi
            </Link>
        <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
        <Button color="primary" onClick={onMoveBack}>
            Wstecz
            </Button>
        <Button color="primary" variant="contained" onClick={onProceed} disabled={(playerType === PlayerType.TRIAL && !phoneVerified && isPhoneVerificationForTrialNeeded) || !tocApproved}>
            Dalej
            </Button>
    </>)
}

const mapStateToProps = (state: AppState) => ({
    playerType: state.registration.playerType,
    playerInfo: state.registration.updatedPlayer,
    isVerifying: state.registration.isVerifying,
    phoneVerified: state.registration.phoneVerified,
    leagueSelection: state.registration.leagueSelection,
    leagues: state.leagues.items,
    currentPlayer: currentPlayer(state),
    priceInfo: state.registration.priceInfo,
    phoneAuthMessage: state.registration.sendingPhoneAuthMessage
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    sendSmsWithVericationCode: (token: string, phoneNumber: string) => dispatch(sendSmsVerificationCode({ token, phoneNumber })),
    verifySmsCode: (token: string, code: string) => dispatch(verifySmsCode({ token, code })),
    getPriceInfoForLeague: (leagueId: string, leagueYear: string, promoCode: string, referral: string, playerId?: string) => dispatch(getPriceInfoForLeague({
        leagueId,
        leagueYear,
        promoCode,
        referral,
        playerId
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);