import React from "react";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import RegistrationWizard from "./RegistrationWizard";
import { useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { setRegistrationForPlayerTypeUpgrade } from "../../store/registration/actions";

export interface RegistrationPageProps {
    player: Data<Player>;

    upgradePlayer: (leagueId: string, leagueYear: string, player: Player) => void;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const RegistrationPage: React.FC<RegistrationPageProps> = ({ player, upgradePlayer }) => {

    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const [startingStep, setStartingStep] = React.useState(0);

    const query = useQuery();

    React.useEffect(() => {

        if(player.type === "LOADED" && player.value) {

            if(query.has('leagueId')  && query.has('leagueYear')) {
                upgradePlayer(query.get('leagueId')!, query.get('leagueYear')!, player.value!);
                setStartingStep(3);
            }
        }    

    }, [player, query.get('leagueId'), query.get('leagueYear')]);

    return (<>
        {
            !isAuthenticated ? (
                <Alert severity="info">
                    <div>
                        <Link color="textPrimary" href="" onClick={() => loginWithRedirect({
                            redirectUri: window.location.href, appState: {
                                targetUrl: window.location.hash
                            }
                        })}><b>Kliknij tutaj</b></Link><span> aby zalogować się lub założyć konto w celu rozpoczęcia rejestracji.</span>
                    </div>
                </Alert>
            ) : (
                    <RegistrationWizard startingStep={startingStep}></RegistrationWizard>
                )
        }
    </>);
};

const mapStateToProps = (state: AppState) => ({
    player: currentPlayer(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    upgradePlayer: (leagueId: string, leagueYear: string, player: Player) => dispatch(setRegistrationForPlayerTypeUpgrade({
        leagueId,
        leagueYear,
        player
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);