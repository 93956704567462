import { Player } from "../players/models";
import { MatchOfferHeader } from "./models";
import { LeagueWithPlayersICanPlay } from "../leagues/models";

export const canBeTurnedIntoMatch = (offer: MatchOfferHeader, player?: Player, league?: LeagueWithPlayersICanPlay) => 
    offer.clubs && 
    offer.startDate &&
    offer.endDate &&
    offer.startTime &&
    offer.endTime &&
    offer.clubs.length === 1 && 
    offer.startDate.valueOf() === offer.endDate.valueOf() &&
    offer.startTime === offer.endTime && 
    !!player && player.id !== offer.firstPlayer.id &&
    !!league && !!league.players.find(p => p.id === player.id);

export const canBeCancelled = (offer: MatchOfferHeader, player?: Player) => 
    player && offer.firstPlayer.id === player.id;

export interface MatchOffersFilterModel {
    dateFrom: Date;
    dateTo: Date;
    clubIds: string[]
}