import { League, LeagueAccess } from "../leagues/models";
import { Match, MatchHeader, Set } from "./models";
import { getConfig } from "../../config/configuration";
import { GetPlayersMatchesPayload } from "./actions";

export async function submitMatchApi(league: League, match: Match, token: string) {

    var config = await getConfig();

    const url = league.type.access === LeagueAccess.Public 
        ? `/leagues/open/${league.year}/${league.id}/matches`
        : `not-exist`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            oponent: match.oponent.id,
            date: match.date,
            club: match.club.id
        })
    });

    if(response.status >= 200 && response.status < 300) 
        return true;
    if(response.status === 400) {
        return await response.json()
    } else {
        throw new Error(response.statusText);
    }
}

export async function getNewestMatchResultsApi(leagueId: string, year: string) {
    var config = await getConfig();

    const url = `/leagues/open/${year}/${leagueId}/matches/newest`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const matches: MatchHeader[] = await response.json();
    return matches.map(m => ({...m, date: new Date(m.date)}));
}

export async function getLeagueMatchesApi(year: string, id: string, take: number, skip: number) {
    var config = await getConfig();

    const url = `/leagues/open/${year}/${id}/matches?take=${take}&skip=${skip}`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const matches: MatchHeader[] = await response.json();
    return matches.map(m => ({...m, date: new Date(m.date)}));
}

export async function getTournamentMatchesApi(year: string, id: string, take: number, skip: number) {
    var config = await getConfig();

    const url = `/tournaments/${year}/${id}/matches?take=${take}&skip=${skip}`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const matches: MatchHeader[] = await response.json();
    return matches.map(m => ({...m, date: new Date(m.date)}));
}

export async function getPlayersMatchesApi(request: GetPlayersMatchesPayload) {
    var config = await getConfig();

    const { id, year, skip, take, token } = request;

    const url = `/leagues/open/${year}/${id}/matches/mine?take=${take}&skip=${skip}`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const matches: MatchHeader[] = await response.json();
    return matches.map(m => ({...m, date: new Date(m.date)}));
}

export async function getPlayersTournamentMatchesApi(request: GetPlayersMatchesPayload) {

    var config = await getConfig();

    const { id, year, skip, take, token } = request;

    const url = `/tournaments/${year}/${id}/matches/mine?take=${take}&skip=${skip}`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const matches: MatchHeader[] = await response.json();
    return matches.map(m => ({...m, date: new Date(m.date)}));

}

export async function confirmMatchApi(year: string, leagueId: string, matchId: string, token: string) {
    var config = await getConfig();

    const url = `/leagues/open/${year}/${leagueId}/matches/${matchId}/confirm`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST'
    });

    if(response.ok) {
        return true;
    }

    if(response.status === 400) {
        return await response.json();
    }

    throw new Error(response.statusText);
}

export async function confirmTournamentMatchApi(year: string, tournamentId: string, matchId: string, token: string) {
    const config = await getConfig();

    const url = `/tournaments/${year}/${tournamentId}/matches/${matchId}/confirm`;

    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST'
    });

    if(response.ok) {
        return true;
    }

    if(response.status === 400) {
        return await response.json();
    }

    throw new Error(response.statusText);
}

export async function cancelMatchApi(year: string, leagueId: string, matchId: string, token: string): Promise<MatchHeader> {
    var config = await getConfig();

    const url = `/leagues/open/${year}/${leagueId}/matches/${matchId}/reject`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST'
    });

    const match:MatchHeader = await response.json();
    return {...match, date: new Date(match.date)};
}

export async function cancelTournamentMatchApi(year: string, tournamentId: string, matchId: string, token: string): Promise<MatchHeader> {
    var config = await getConfig();

    const url = `/tournaments/${year}/${tournamentId}/matches/${matchId}/reject`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST'
    });

    const match:MatchHeader = await response.json();
    return {...match, date: new Date(match.date)};
}

export async function submitMatchResultApi(year: string, tournamentId: string, matchId: string, sets: Set[], token: string) {
    var config = await getConfig();

    const url = `/leagues/open/${year}/${tournamentId}/matches/${matchId}/result`;

    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ setScores: sets.filter(r => r.firstPlayer > 0 || r.secondPlayer > 0) }),
        method: 'POST'
    });

    if(response.ok) {
        return true;
    }

    if(response.status === 400) {
        return await response.json();
    }

    throw new Error(response.statusText);
}

export async function submitTournamentMatchResultApi(year: string, tournamentId: string, matchId: string, sets: Set[], token: string) {
    var config = await getConfig();

    const url = `/tournaments/${year}/${tournamentId}/matches/${matchId}/result`;

    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ setScores: sets.filter(r => r.firstPlayer > 0 || r.secondPlayer > 0) }),
        method: 'POST'
    });

    if(response.ok) {
        return true;
    }

    if(response.status === 400) {
        return await response.json();
    }

    throw new Error(response.statusText);
}