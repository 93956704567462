import { takeEvery, call, put } from "redux-saga/effects";
import { SUBMIT_MATCH, GET_NEWEST_MATCHES, GET_PLAYERS_MATCHES, CONFIRM_MATCH, CANCEL_MATCH, SUBMIT_MATCH_RESULT, GET_LEAGUES_MATCHES, GET_PLAYERS_TOURNAMENT_MATCHES, CONFIRM_TOURNAMENT_MATCH, CANCEL_TOURNAMENT_MATCH, SUBMIT_TOURNAMENT_MATCH_RESULT, GET_TOURNAMENT_MATCHES } from "./actionTypes";
import { submitMatchApi, getNewestMatchResultsApi, getPlayersMatchesApi, confirmMatchApi, cancelMatchApi, submitMatchResultApi, getLeagueMatchesApi, getPlayersTournamentMatchesApi, confirmTournamentMatchApi, cancelTournamentMatchApi, submitTournamentMatchResultApi, getTournamentMatchesApi } from "./api";
import { SubmitMatch, submitMatchFailed, submitMatchSuccess, GetNewestMatches, getNewestMatches, getNewestMatchesFailed, getNewestMatchesSuccess, GetPlayersMatches, getPlayersMatchesSuccess, getPlayersMatchesFailed, ConfirmMatch, getPlayersMatches, CancelMatch, confirmMatchSuccess, confirmMatchFailed, cancelMatchFailed, cancelMatchSuccess, SubmitMatchResult, submitMatchResultSuccess, submitMatchResultFailed, GetLeaguesMatches, getLeaguesMatchesSuccess, getLeaguestMatchesFailed, GetPlayersTournamentMatches, getPlayersTournamentMatchesSuccess, getPlayersTournamentMatchesFailed, ConfirmTournamentMatch, confirmTournamentMatchFailed, confirmTournamentMatchSuccess, getPlayersTournamentMatches, CancelTournamentMatch, SubmitTournamentMatchResult, cancelTournamentMatchSuccess, submitTournamentMatchResultSuccess, submitTournamentMatchResultFailed, cancelTournamentMatchFailed, GetTournamentMatches, getTournamentMatchesSuccess, getTournamentMatchesFailed } from "./actions";
import { loadLeagueDetails, loadOpponentsPlayedWith } from "../leagues/actions";
import ReactGA from 'react-ga';
import { loadMyConfirmedMatches, loadMyUnconfirmedMatches } from "../desktop/actions";

function* submitMatchStep({ payload } : SubmitMatch) {
    try {
        const { league, match, token } = payload;
        const result = yield call(submitMatchApi, league, match, token );
        if(result === true) {
            ReactGA.event({ action: 'Match submitted', category: 'Matches' });
            yield put(submitMatchSuccess());
            yield put(loadOpponentsPlayedWith({ token, year: league.year, id: league.id }))
            yield put(loadLeagueDetails(league));
            yield put(getNewestMatches({ leagueId: league.id, year: league.year }));
        } else {
            yield put(submitMatchFailed(result));
        }
    } catch(e) {
        yield put(submitMatchFailed(e.message));
    }
}

function* getNewestMatchesStep({ payload }: GetNewestMatches) {
    try {
        const { leagueId, year } = payload;
        const result = yield call(getNewestMatchResultsApi, leagueId, year );

        yield put(getNewestMatchesSuccess(result));
    } catch(e) {
        yield put(getNewestMatchesFailed(e.message));
    }
}

function* getPlayersMatchesStep({ payload }: GetPlayersMatches) {
    try {
        const result = yield call(getPlayersMatchesApi, payload);
         
        yield put(getPlayersMatchesSuccess(result));
    }
    catch(e) {
        yield put(getPlayersMatchesFailed(e));
    }
}

function* confirmMatchStep({ payload }: ConfirmMatch) {
    try {
        const { year, leagueId, matchId, token, take, skip } = payload;
        const result = yield call(confirmMatchApi, year, leagueId, matchId, token);
        if(result === true) {
            ReactGA.event({ action: 'Match confirmed', category: 'Matches' });
            yield put(confirmMatchSuccess());
        } else {
            yield put(confirmMatchFailed(result));
        }
        yield put(getPlayersMatches({ id: leagueId, skip, take, token, year }))
        yield put(loadMyUnconfirmedMatches(token));
        yield put(loadMyConfirmedMatches(token));
    } catch(e) {
        yield put(confirmMatchFailed(e));
    }
}

function* confirmTournamentMatchStep({ payload }: ConfirmTournamentMatch) {
    try {
        const { matchId, skip, take, token, tournamentId, year } = payload;
        const result = yield call(confirmTournamentMatchApi, year, tournamentId, matchId, token);
        if(result === true) {
            yield put(confirmTournamentMatchSuccess());
        } else {
            yield put(confirmTournamentMatchFailed(result));
        }

        yield put(getPlayersTournamentMatches({ id: tournamentId, skip, take, token, year }));

    } catch(e) {
        yield put(confirmTournamentMatchFailed(e));
    }
}

function* cancelMatchStep({ payload }: CancelMatch) {
    try {
        const { year, leagueId, matchId, token, take, skip } = payload;
        const result = yield call(cancelMatchApi, year, leagueId, matchId, token);
        ReactGA.event({ action: 'Match cancelled', category: 'Matches' });
        yield put(cancelMatchSuccess());
        yield put(getPlayersMatches({ id: leagueId, skip, take, token, year }))
        yield put(loadMyUnconfirmedMatches(token));
        yield put(loadMyConfirmedMatches(token));
    } catch(e) {
        yield put(cancelMatchFailed(e));
    }
}

function* cancelTournamentMatchStep({ payload }: CancelTournamentMatch) {
    try {
        const { year, tournamentId, matchId, token, take, skip } = payload;
        const result = yield call(cancelTournamentMatchApi, year, tournamentId, matchId, token);
        yield put(cancelTournamentMatchSuccess());
        yield put(getPlayersTournamentMatches({ id: tournamentId, skip, take, token, year }))
    } catch(e) {
        yield put(cancelTournamentMatchFailed(e));
    }
}

function* submitMatchResultStep({ payload }: SubmitMatchResult) {
    try {
        const { year, leagueId, matchId, sets, token, skip, take } = payload;
        const result = yield call(submitMatchResultApi, year, leagueId, matchId, sets, token);
        if(result === true) {
            ReactGA.event({ action: 'Match result submitted', category: 'Matches' });
            yield put(submitMatchResultSuccess());
        } else {
            yield put(submitMatchResultFailed(result));
        }
        
        yield put(getPlayersMatches({ id: leagueId, skip, take, token, year }));
        yield put(loadMyUnconfirmedMatches(token));
        yield put(loadMyConfirmedMatches(token));
    } catch(e) {
        yield put(submitMatchResultFailed(e));
    }
    
}

function* submitTournamentMatchResultStep({ payload }: SubmitTournamentMatchResult) {
    try {
        const { year, tournamentId, matchId, sets, token, skip, take } = payload;
        const result = yield call(submitTournamentMatchResultApi, year, tournamentId, matchId, sets, token);
        if(result === true) {
            yield put(submitTournamentMatchResultSuccess());
        } else {
            yield put(submitTournamentMatchResultFailed(result));
        }
        
        yield put(getPlayersTournamentMatches({ id: tournamentId, skip, take, token, year }));
    } catch(e) {
        yield put(submitTournamentMatchResultFailed(e));
    }
    
}

function* getLeagueMatchesStep({ payload }: GetLeaguesMatches) {
    try {
        const { id, year, take, skip } = payload;
        const result = yield call(getLeagueMatchesApi, year, id, take, skip);
        
        yield put(getLeaguesMatchesSuccess(result));
    } catch(e) {
        yield put(getLeaguestMatchesFailed(e));
    }
}

function* getTournamentMatchesStep({ payload } : GetTournamentMatches) {
    try {
        const { id, year, take, skip } = payload;
        const result = yield call(getTournamentMatchesApi, year, id, take, skip);
        yield put(getTournamentMatchesSuccess(result));
    } catch(e) {
        yield put(getTournamentMatchesFailed(e));
    }
}

function* getPlayersTournamentMatchesStep({ payload }: GetPlayersTournamentMatches) {
    try {
        const result = yield call(getPlayersTournamentMatchesApi, payload);

        yield put(getPlayersTournamentMatchesSuccess(result));
    } catch(e) {
        yield put(getPlayersTournamentMatchesFailed(e));
    }
}

export function* submitMatchSaga() {
    yield takeEvery(SUBMIT_MATCH, submitMatchStep);
}

export function* getNewestMatchesSaga() {
    yield takeEvery(GET_NEWEST_MATCHES, getNewestMatchesStep);
}

export function* loadPlayersMatchesSaga() {
    yield takeEvery(GET_PLAYERS_MATCHES, getPlayersMatchesStep);
}

export function* loadPlayersTournamentMatchesSaga() {
    yield takeEvery(GET_PLAYERS_TOURNAMENT_MATCHES, getPlayersTournamentMatchesStep);
}

export function* confirmMatchSaga() {
    yield takeEvery(CONFIRM_MATCH, confirmMatchStep);
}

export function* cancelMatchSaga() {
    yield takeEvery(CANCEL_MATCH, cancelMatchStep);
}

export function* submitMatchResultSaga() {
    yield takeEvery(SUBMIT_MATCH_RESULT, submitMatchResultStep);
}

export function* getLeagueMatchesSage() {
    yield takeEvery(GET_LEAGUES_MATCHES, getLeagueMatchesStep);
}

export function* getTournamentMatchesSaga() {
    yield takeEvery(GET_TOURNAMENT_MATCHES, getTournamentMatchesStep);
}

export function* confirmTournamentMatchSaga() {
    yield takeEvery(CONFIRM_TOURNAMENT_MATCH, confirmTournamentMatchStep);
}

export function* cancelTournamentMatchSaga() {
    yield takeEvery(CANCEL_TOURNAMENT_MATCH, cancelTournamentMatchStep);
}

export function* submitTournamentMatchResultSaga() {
    yield takeEvery(SUBMIT_TOURNAMENT_MATCH_RESULT, submitTournamentMatchResultStep);
}